import React from "react";
import {Button} from "carbon-components-react";
import { ArrowLeft16 } from "@carbon/icons-react";
import { navigate, useStaticQuery, graphql } from "gatsby";
import ReactHtmlParser from 'react-html-parser'

function About({ data }) {
  let qry = useStaticQuery(graphql`
    {
      nodeArticle {
        title
        body {
          processed
          value
        }
      }
    }
  `)
  let abouttitle = qry.nodeArticle.title 
  ? qry.nodeArticle.title 
  : ''
  let aboutcontent = qry.nodeArticle.body.value 
  ? qry.nodeArticle.body.value 
  : ''
  const aboutLayout = (
    <div className="faqsansw">
      <h4 className="reqtitle">The HEED Ophthalmic Foundation</h4> 
        <br/>
        <br/>
        <img src="https://heed-site-prod-backend.parallelpublicworks.com/sites/default/files/2023-03/iStock-172466251.jpg" style={{margin: "auto"}} />
        <br/>
        <br/>
      <div className="faqsansw">
            {ReactHtmlParser(aboutcontent)}
            <br/>
      </div>
    </div>
  );
  return aboutLayout;
}

export default function aboutPage({ pageContext, location }) {
  pageContext = {
    ...pageContext,
    frontmatter: { title: "HEED Fellowship Application", tabs: ["Profile", "About"] },
  };
  return (
    <About
      pageContext={pageContext}
      location={location}
    />
  );
}
